import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    //If the brand ends with a 's' -> do nothing. Else: add a 's'
    generateGenitive(customerCarBrand: string | null): string {
        if (customerCarBrand == null || customerCarBrand == '') {
            return ''
        } else {
            if (customerCarBrand.toLowerCase().endsWith('s')) {
                return customerCarBrand;
            } else {
                return customerCarBrand + 's';
            }
        }
    }
}
