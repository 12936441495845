import { NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { ShieldModule } from "@shield/angular";
import { UtilityService } from "../../service/utility.service";

@Component({
  selector: "app-hero",
  standalone: true,
  imports: [ShieldModule, NgIf, ReactiveFormsModule],
  templateUrl: "./hero.component.html",
  styleUrl: "./hero.component.scss",
})
export class HeroComponent {
  @Input() customerCarBrand: string | null = null;
  @Input() customerCarOffer: string | null = null;
  imageLoaded: boolean = false;
  showDialog: boolean = false;

  constructor(protected utilityService: UtilityService) {}

  openPriceInfo() {
    this.showDialog = true;
  }

  closePriceInfo() {
    this.showDialog = false;
  }
}
