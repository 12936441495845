import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { datadogRum } from '@datadog/browser-rum';
import {environment} from "./environments/environment";
bootstrapApplication(AppComponent, appConfig)
    .catch((err) => console.error(err));


datadogRum.init({
    applicationId: 'b582887c-d455-41ad-be86-64bc7d8e9084',
    clientToken: 'pub0989a1791e0c9676482a24d796c2eb4e',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'datadoghq.eu',
    service: 'eco2-autoankauf',
    env: environment.ENVIRONMENT,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackingConsent: 'not-granted',
    defaultPrivacyLevel: 'mask-user-input',
});