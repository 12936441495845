<div class="dialog-container">
  <google-map
    *ngIf="googleMapsOptions | async as options"
    class="google-maps-component"
    height="100%"
    width="100%"
    [center]="center"
    [options]="options"
    [zoom]="zoom"
  >
    <ng-container *ngIf="googleMapsMarker | async as mapsMarker">
      @for (marker of mapsMarker; track marker) {
      <map-advanced-marker
        #markerElem="mapAdvancedMarker"
        [position]="marker.position"
        [content]="$any(marker.content)"
        (mapClick)="onMarkerClick(marker)"
      />
      }
    </ng-container>
    <div
      *ngIf="!isPartnerInformationLoaded && !isTimeslotInformationLoaded"
      class="mask"
    ></div>
  </google-map>

  <div
    #selectLocationContainer
    class="select-location-container s:pt-6 s:pb-6 s:pl-4 s:pr-4 s:overflow-auto"
  >
    <span class="s:text s:text-bold s:text-body-2">
      HUK-Ankaufstation auswählen
    </span>
    <s-choice-list *ngIf="partnerLocations" direction="vertical">
      <s-choice-list-item
        *ngFor="let location of partnerLocations; let i = index"
        value="option-{{ i }}"
        [disabled]="!isPartnerInformationLoaded && !isTimeslotInformationLoaded"
        [selected]="location.selected"
        [leadingText]="location.name"
        [supportingText]="location.address"
        (click)="selectLocation(location)"
      >
      </s-choice-list-item>
    </s-choice-list>
  </div>
</div>
