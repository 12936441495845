import { Component } from '@angular/core';
import {ShieldModule} from "@shield/angular";

@Component({
  selector: 'app-price-calculation',
  standalone: true,
    imports: [
        ShieldModule
    ],
  templateUrl: './price-calculation.component.html',
  styleUrl: './price-calculation.component.scss'
})
export class PriceCalculationComponent {

}
