<div class="container">
  <div class="header">
    <h3>HUK-Ankaufstation finden</h3>
  </div>
  <s-divider></s-divider>
  <div class="content">
    <div
      *ngIf="offerPartner"
      class="selected-partner s:cursor-pointer"
      (click)="openSelectLocationDialog()"
    >
      <div class="left">
        <s-icon
          slot="iconStartInner"
          size="md"
          name="home"
          variant="filled"
        ></s-icon>
      </div>
      <div class="right">
        <p class="partner-name">{{ offerPartner.name }}</p>
        <p class="partner-address">{{ offerPartner.address }}</p>
        <a>Ankaufstation ändern</a>
      </div>
    </div>

    <!-- Date-Picker -->
    <div *ngIf="loadingTimeslots" class="skeleton-text">Datum auswählen</div>
    <div *ngIf="loadingTimeslots" class="s:skel skeleton"></div>

    <div *ngIf="!loadingTimeslots">
      <div>
        <s-select (sChange)="setSlotsForDay($event.detail[0])">
          <s-menu-item
            *ngFor="let day of availableDays; index as i"
            value="option-{{ i }}"
            label="{{ formatDate(day) }}"
            activated="{{ i == 0 }}"
          >
          </s-menu-item>

          <s-icon
            slot="iconStartInner"
            size="md"
            name="calendar-month"
            variant="filled"
          ></s-icon>
          <span slot="label">Datum auswählen</span>
        </s-select>

        <s-choice-list
          *ngIf="
            daySelected && availableSlotsOfDay.length > 0;
            else noTimeSlotsAvailable
          "
          direction="vertical"
          [attr.severity]="timeslotSeverity"
        >
          <s-choice-list-item
            *ngFor="let timeslot of availableSlotsOfDay; index as i"
            value="option-{{ i }}"
            leadingText="{{ formatTimeslot(timeslot.start, timeslot.end) }}"
            (click)="selectTimeslot(i)"
            selected="{{ isTimeslotSelected(i) }}"
          >
            <s-icon
              slot="icon"
              size="md"
              name="schedule"
              variant="filled"
            ></s-icon>
          </s-choice-list-item>
          <span slot="label">Verfügbaren Termin wählen</span>
        </s-choice-list>
        <span
          *ngIf="timeslotSeverity == 'critical'"
          class="s:text-critical s:text-caption"
          slot="helptext"
        >
          <s-icon name="error" size="xxs" variant="outlined"></s-icon>
          Bitte einen Termin auswählen
        </span>
        <ng-template #noTimeSlotsAvailable>
          <s-banner
            color="critical"
            dismissible="false"
            icon="true"
            variant="outlined"
          >
            <s-icon
              class="s:text-critical"
              slot="icon"
              variant="filled"
              name="warning"
              size="md"
            ></s-icon>
            <div slot="content">
              <p class="s:text-body-2">
                Es konnte leider kein Termin gefunden werden. Bitte wählen Sie
                ein anderes Datum.
              </p>
            </div>
          </s-banner>
        </ng-template>
      </div>
      <div *ngIf="moreTimeslotsAvailable" class="more-button">
        <s-button color="neutral" variant="text" (click)="loadMoreTimeslots()">
          <s-icon variant="filled" name="add-circle" size="md"></s-icon>
          <span>Weitere Zeiten zeigen</span>
        </s-button>
      </div>
    </div>
  </div>
</div>

<s-dialog
  *ngIf="showSelectLocationDialog && isGoogleMapsLoaded"
  #selectLocationDialog
  id="selectLocationDialog"
  class="select-location-dialog"
  close-button="true"
  dismissible="true"
  [headline]="selectLocationDialogHeadline"
  (sCloseDialog)="onSelectLocationDialogCloseClick()"
>
  <div class="s:p-0">
    <app-select-location
      (selectedPartnerLocation)="onSelectedPartnerLocationChange($event)"
    ></app-select-location>
  </div>
  <form class="s:pt-6 s:pl-4 s:pr-4" method="dialog" slot="append">
    <menu
      class="s:d-flex s:justify-center"
      style="--grid-gap-x: var(--spacing-2)"
    >
      <s-button
        full-width="true"
        class="s:w-full"
        [disabled]="!isPartnerInformationLoaded && !isTimeslotInformationLoaded"
        [loading]="!isTimeslotInformationLoaded"
        (click)="applyNewLocation()"
        >Auswahl bestätigen</s-button
      >
    </menu>
  </form>
</s-dialog>
