<s-divider class="s:pt-4"></s-divider>
<h1 class="s:text-body-1 s:pt-4 s:pb-4 s:text-bold">Teilnahmebedingungen</h1>
<p class="s:text-caption">
  Wer im Aktionszeitraum vom 21.10.24 00:00 Uhr bis 05.12.24 23:59 Uhr einen
  Termin zur Durchführung eines unverbindlichen Bewertungstermins seines
  Gebrauchtwagen vor Ort vereinbart und den Termin im oben genannten Zeitraum
  wahrnimmt, der kann den angegeben Preis erzielen (je nach tatsächlichem
  Zustand des Fahrzeugs: Begutachtung vor Ort ist ausschlaggebend für die
  Ermittlung des tatsächlichen Preises).
</p>
<s-divider class="s:pt-4 s:pb-4"></s-divider>
