import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-page-not-found",
  standalone: true,
  imports: [],
  templateUrl: "./page-not-found.component.html",
})
export class PageNotFoundComponent implements OnInit {
  public ngOnInit(): void {
    document.location.href = "https://www.huk24.de/404-fehler";
  }
}
