<div class="container">
  <div class="header">
    <h3>Ihr Termin bei {{ dataShareService.selectedPartner }}</h3>
  </div>
  <s-divider></s-divider>
  <div class="content">
    <p class="instruction">
        Terminbestätigung für Ihren {{ dataShareService.carBrand }}
    </p>
    <div class="key-information">
      <div class="row">
        <s-icon
          slot="iconStartInner"
          size="md"
          name="calendar-month"
          variant="filled"
        ></s-icon>
        <p>{{ dataShareService.selectedDay }}</p>
      </div>
      <div class="row">
        <s-icon
          slot="iconStartInner"
          size="md"
          name="schedule"
          variant="filled"
        ></s-icon>
        <p>{{ dataShareService.selectedTimeslot }}</p>
      </div>
      <div class="row">
        <s-icon
          slot="iconStartInner"
          size="md"
          name="location-on"
          variant="filled"
        ></s-icon>
        <div class="location">
          <p>{{ dataShareService.selectedPartner }}</p>
          <p class="s:text-subdued">
            {{ dataShareService.selectedPartnerAddress }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="checklist">
    <p>Diese Unterlagen benötigen wir beim Termin von Ihnen:</p>
    <div class="entry">
      <s-icon
        slot="iconStartInner"
        size="md"
        variant="filled"
        name="check-circle"
      ></s-icon>
      <p>Fahrzeugbrief</p>
    </div>
    <div class="entry">
      <s-icon
        slot="iconStartInner"
        size="md"
        variant="filled"
        name="check-circle"
      ></s-icon>
      <p>Fahrzeugschein</p>
    </div>
    <div class="entry">
      <s-icon
        slot="iconStartInner"
        size="md"
        variant="filled"
        name="check-circle"
      ></s-icon>
      <p>Personalausweis/Reisepass</p>
    </div>
    <div class="entry">
      <s-icon
        slot="iconStartInner"
        size="md"
        variant="filled"
        name="check-circle"
      ></s-icon>
      <p>Bankkarte</p>
    </div>
    <p>Falls vorhanden:</p>
    <div class="entry">
      <s-icon
        slot="iconStartInner"
        size="md"
        variant="outlined"
        name="check-circle"
      ></s-icon>
      <p>Letzte HU/AU Bescheinigung</p>
    </div>
    <div class="entry">
      <s-icon
        slot="iconStartInner"
        size="md"
        variant="outlined"
        name="check-circle"
      ></s-icon>
      <p>Optional: Alle Ersatzschlüssel</p>
    </div>
  </div>
</div>
