import { Component } from "@angular/core";
import { ShieldModule } from "@shield/angular";

@Component({
  selector: "app-cross-selling",
  standalone: true,
  imports: [ShieldModule],
  templateUrl: "./cross-selling.component.html",
  styleUrl: "./cross-selling.component.scss",
})
export class CrossSellingComponent {
  public openUrl(url: string): void {
    window.open(url, "_blank");
  }
}
