<div class="container">
  <div class="header">
    <h3>Bei Fragen</h3>
  </div>
  <s-divider></s-divider>
  <div class="content">
    <p class="subheading">
      Bei Fragen zu Ihrem Autoverkauf steht Ihnen die HUK-Autowelt jederzeit zur
      Verfügung.
    </p>
    <div class="row">
      <s-icon
        slot="iconStartInner"
        size="md"
        variant="filled"
        name="call"
      ></s-icon>
      <p>0211 54232350</p>
    </div>
    <div class="row">
      <s-icon
        slot="iconStartInner"
        size="md"
        variant="filled"
        name="mail"
      ></s-icon>
      <p>info&#64;huk-autowelt.de</p>
    </div>
  </div>
  <s-divider></s-divider>
  <div class="bottom">
    <p class="question">Sie können den Termin doch nicht wahrnehmen?</p>
    <p>
      Dann kontaktieren Sie uns. Wir finden einen neuen Termin für Sie oder
      können Ihren Termin stornieren.
    </p>
  </div>
</div>
