<div class="header">
    <div class="content">
        <a href="https://www.huk24.de/">
            <img class="logo-desktop" ngSrc="assets/header/huk24-logo-desktop.svg" alt="HUK24"
                 height="16" width="16"/>
            <img class="logo-small"
                 ngSrc="assets/header/huk24-logo-mobile.svg" alt="HUK24" height="16" width="16"/>
        </a>
    </div>
</div>
