<div>
  <div class="image-mobile">
    <img
      src="assets/hero-image.webp"
      alt="Ein Mann steht vor einem Gebäude mit dem Logo 'HUK Autowelt' und mehreren geparkten Autos."
      (load)="imageLoaded = true"
    />
  </div>
  <div class="heading">
    <div class="left">
      <p class="subheading">HUK24 präsentiert: Die HUK-AUTOWELT</p>
      <div class="s:text s:text-bold s:text-h2 s:pb-1">
        Wir kaufen Ihren {{ customerCarBrand }} für
        <span class="s:d-inline-flex s:items-center s:text-nowrap">
          {{ customerCarOffer }}€
          <s-icon
            class="price-info s:text s:p-3 s:cursor-pointer"
            size="md"
            variant="outlined"
            name="info"
            (click)="openPriceInfo()"
          ></s-icon>
        </span>
      </div>
      <div class="content">
        <div class="text">
          <div class="entry">
            <s-icon
              slot="iconStartInner"
              size="md"
              variant="filled"
              name="done"
            ></s-icon>
            <p class="s:text-body-1">
               Ein exklusives Angebot, perfekt auf Ihr Fahrzeug abgestimmt
            </p>
          </div>
          <div class="entry">
            <s-icon
              slot="iconStartInner"
              size="md"
              variant="filled"
              name="done"
            ></s-icon>
            <p class="s:text-body-1">
              Überweisung des Verkaufpreises innerhalb von 3 Tagen
            </p>
          </div>
          <div class="entry">
            <s-icon
              slot="iconStartInner"
              size="md"
              variant="filled"
              name="done"
            ></s-icon>
            <p class="s:text-body-1">Kostenlose Abmeldung Ihres Fahrzeugs</p>
          </div>
        </div>
      </div>
    </div>
    <div class="image-desktop">
      <div
        class="skeleton s:skel s:border-radius-full"
        *ngIf="!imageLoaded"
      ></div>
      <img
        src="assets/hero-image.webp"
        alt="Ein Mann steht vor einem Gebäude mit dem Logo 'HUK Autowelt' und mehreren geparkten Autos."
        (load)="imageLoaded = true"
      />
    </div>
  </div>
</div>

<s-dialog
  close-button="true"
  dismissible="true"
  show="{{ showDialog }}"
  (sCloseDialog)="closePriceInfo()"
>
  <h6 slot="prepend">Wie kommt der Preis zustande?</h6>
  <div>
      <p class="s:text-body-1">
          Dieses Ankaufsangebot stellt lediglich eine Schätzung des erzielbaren Kaufpreises dar. Dabei gehen wir von einem
          einwandfreien Fahrzeug aus, das alters- und laufleistungstypische Verschleißerscheinungen aufweist.
      </p>
      <p class="s:text-body-1 s:pt-4">
          Der tatsächliche Zustand und somit das verbindliche Ankaufsangebot kann erst vor Ort nach einer Begutachtung durch
          unsere Experten ermittelt werden.
      </p>
  </div>
</s-dialog>
