import { DOCUMENT, NgIf } from "@angular/common";
import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { ShieldModule } from "@shield/angular";
import { take } from "rxjs";
import { CrossSellingComponent } from "../cross-selling/cross-selling.component";
import { ISuccessResponse } from "../model/partner.model";
import { NextStepsComponent } from "../next-steps/next-steps.component";
import { DataShareService } from "../service/data-share.service";
import { PartnerService } from "../service/partner.service";
import { SummaryComponent } from "./summary/summary.component";
import { SupportComponent } from "./support/support.component";

@Component({
  selector: "app-success",
  standalone: true,
  imports: [
    CrossSellingComponent,
    NextStepsComponent,
    NgIf,
    SummaryComponent,
    SupportComponent,
    ShieldModule,
  ],
  templateUrl: "./success.component.html",
  styleUrl: "./success.component.scss",
})
export class SuccessComponent implements OnInit, AfterViewInit {
  @ViewChild("loadingContainer") loadingContainer!: ElementRef;
  public isSuccessDataLoaded = false;
  private offerId: string = "";
  private successId: string = "";
  private readonly destroyRef = inject(DestroyRef);
  private readonly partnerService = inject(PartnerService);

  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected dataShareService: DataShareService,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    const elements = document.getElementsByClassName("wrapper");
    if (elements.length != 0) {
      elements[0].scrollIntoView({ behavior: "smooth" });
    }

    this.router.queryParamMap.pipe(take(1)).subscribe((queryParamMap) => {
      const offerId = queryParamMap.get("offer_id");
      const successId = queryParamMap.get("success_id");
      if (offerId != null && successId != null) {
        this.offerId = offerId;
        this.successId = successId;
        this.getSuccessInformation();
      } else {
        this.call404();
      }
    });
  }

  public ngAfterViewInit(): void {
    if (this.loadingContainer) {
      const headerHeight = document.querySelector(".header")?.clientHeight;
      const footerHeight = document.querySelector(".footer")?.clientHeight;
      if (headerHeight && footerHeight) {
        const height = innerHeight - headerHeight - footerHeight - 66; // 66px from footer wrapper padding
        this.loadingContainer.nativeElement.style.height = `${height}px`;
      }
    }
  }

  private call404(): void {
    this.document.location.href = "https://www.huk24.de/404-fehler";
  }

  private getSuccessInformation() {
    this.partnerService
      .getSuccesInformation(this.offerId, this.successId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((successData) => {
        if (!successData) {
          this.call404();
        } else {
          this.setSuccessData(successData);
        }
      });
  }

  private getLocaleDateStringWithLeadingZeros(date: string): string {
    const dateObj = new Date(date.slice(0, -6));
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    return `${day < 10 ? "0" : ""}${day}.${
      month < 10 ? "0" : ""
    }${month}.${year}`;
  }

  private getLocalStartAndEndTimeSlot(
    startDate: string,
    endDate: string
  ): string {
    const startTime = new Date(startDate.slice(0, -6))
      .toLocaleTimeString()
      .slice(0, -3);
    const endTime = new Date(endDate.slice(0, -6))
      .toLocaleTimeString()
      .slice(0, -3);
    return `${startTime} - ${endTime}`;
  }

  private setSuccessData(successData: ISuccessResponse) {
    this.dataShareService.carBrand = successData.car_brand;
    this.dataShareService.customerName = successData.customer_name;
    this.dataShareService.price = successData.price;
    this.dataShareService.selectedPartner = successData.branchstore_name;
    this.dataShareService.selectedPartnerAddress =
      successData.branchstore_address;
    if (successData.appointment_start && successData.appointment_end) {
      this.dataShareService.selectedDay =
        this.getLocaleDateStringWithLeadingZeros(successData.appointment_start);
      this.dataShareService.selectedTimeslot = this.getLocalStartAndEndTimeSlot(
        successData.appointment_start,
        successData.appointment_end
      );
      this.isSuccessDataLoaded = true;
    } else {
      this.call404();
    }
  }
}
