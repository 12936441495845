import { Component } from '@angular/core';
import {ShieldModule} from "@shield/angular";

@Component({
  selector: 'app-campaign-over',
  standalone: true,
    imports: [
        ShieldModule
    ],
  templateUrl: './campaign-over.component.html',
  styleUrl: './campaign-over.component.scss'
})
export class CampaignOverComponent {

}
