import { Routes } from "@angular/router";
import { LandingpageComponent } from "./app/landingpage/landingpage.component";
import { PageNotFoundComponent } from "./app/page-not-found/page-not-found.component";
import { SuccessComponent } from "./app/success/success.component";

export enum RouteNames {
  Root = "",
  DataPrivacy = "datenschutz",
  Imprint = "impressum",
  PrivacySettings = "privatsphaere-einstellungen",
  Success = "success",
}

export const routes: Routes = [
  { path: RouteNames.Root, component: LandingpageComponent },
  { path: RouteNames.Success, component: SuccessComponent },
  {
    path: RouteNames.DataPrivacy,
    loadComponent: () =>
      import("./app/data-privacy/data-privacy.component").then(
        (m) => m.DataPrivacyComponent
      ),
  },
  {
    path: RouteNames.Imprint,
    loadComponent: () =>
      import("./app/imprint/imprint.component").then((m) => m.ImprintComponent),
  },
  {
    path: RouteNames.PrivacySettings,
    loadComponent: () =>
      import("./app/privacy/privacy.component").then((m) => m.PrivacyComponent),
  },
  { path: "**", component: PageNotFoundComponent },
];
