import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DataShareService {
  carBrand: string | null = null;
  customerName: string | null = null;
  price: string | null = null;
  selectedPartner: string | null = null;
  selectedPartnerAddress: string | null = null;
  selectedDay: string | null = null;
  selectedTimeslot: string | null = null;
  ng_id: string | null = null;
}
