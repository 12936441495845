<div class="image-mobile">
  <img
    src="assets/cross-selling-image.webp"
    alt="Ein Auto und das Logo der HUK Autowelt"
  />
</div>
<h1>Sie sind auf der Suche nach einem neuem Gebrauchtwagen?</h1>
<div class="content">
  <div class="left">
    <p>
      Im Autoportal der HUK-AUTOWELT finden Sie zahlreiche Modelle
      unterschiedlicher Hersteller zu Top-Preisen und mit DEKRA-geprüfter
      Qualität.
    </p>
    <s-button
      variant="outlined"
      (click)="openUrl('https://www.huk-autowelt.de/')"
    >
      Zum Autoportal der HUK-AUTOWELT
      <s-icon name="chevron-right" size="md" variant="filled"></s-icon>
    </s-button>
  </div>
  <div class="image-desktop">
    <img
      src="assets/cross-selling-image.webp"
      alt="Ein Auto und das Logo der HUK Autowelt"
    />
  </div>
</div>
