import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { catchError, concatMap, delay, retryWhen } from "rxjs/operators";

export interface RetryRequestOptions {
  maximumRetries: number;
  retryDelay: number;
}

@Injectable()
export class HttpRetryErrorsInterceptor implements HttpInterceptor {
  private retryRequestOptions: RetryRequestOptions = {
    maximumRetries: 3,
    retryDelay: 3000,
  };

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      retryWhen((errors: Observable<HttpErrorResponse>) =>
        errors.pipe(
          concatMap((error, retryCount) => {
            if (retryCount + 1 < this.retryRequestOptions.maximumRetries) {
              return of(error).pipe(
                delay(retryCount + 1 * this.retryRequestOptions.retryDelay)
              );
            } else {
              return throwError(() => error);
            }
          })
        )
      ),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          document.location.href = "https://www.huk24.de/404-fehler";
        } else if (error.status >= 500) {
          document.location.href = "https://www.huk24.de/500-fehler";
        }
        return throwError(() => error);
      })
    );
  }
}
