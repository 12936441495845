import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, of, switchMap, tap } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class GoogleMapsService {
  public static readonly showGoogleMapsStorageKey = "showGoogleMaps";
  private mapsApiLoaded = false;

  constructor(private httpClient: HttpClient) {}

  public initMapsApi(): Observable<boolean> {
    return of(this.mapsApiLoaded).pipe(
      switchMap((loaded) => (!loaded ? this.loadMapsApi() : of(true))),
      tap((loaded) => (this.mapsApiLoaded = loaded))
    );
  }

  private loadMapsApi() {
    const apiKey = environment.GOOGLE_MAPS_API_KEY;
    return this.httpClient
      .jsonp(
        `https://maps.googleapis.com/maps/api/js?key=${apiKey}`,
        "callback"
      )
      .pipe(
        map(() => true),
        catchError((err) => {
          console.warn(err);
          return of(false);
        })
      );
  }
}
