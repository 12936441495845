export interface IPartnerLocation {
  branch_store_id: number;
  name: string;
  address: string;
  location: ILocation;
  selected?: boolean;
}

export interface Link {
  rel: string;
  link: string;
}

export interface ILocation {
  latitude: number;
  longitude: number;
}

export interface ISimplePartnerLocation {
  id: number;
  name: string;
  address: string;
}

export class SimplePartnerLocation {
  id: string;
  name: string;
  address: string;

  constructor(id: string, name: string, address: string) {
    this.id = id;
    this.name = name;
    this.address = address;
  }
}

export interface ITimeslots {
  [date: string]: ITimeslot[];
}

export interface ITimeslot {
  uid: string;
  start: string;
  end: string;
}

export class Timeslots implements ITimeslots {
  [date: string]: ITimeslot[];

  constructor() {}
}

export class Timeslot implements ITimeslot {
  uid: string;
  start: string;
  end: string;

  constructor() {
    this.uid = "";
    this.start = "";
    this.end = "";
  }
}

export interface IOffer {
  offer_id: string;
  price: string;
  car_brand: string;
  branch_store_id: string;
  branch_store_name: string;
  branch_store_address: string;
  valid: boolean;
}

export class Offer implements IOffer {
  offer_id: string;
  price: string;
  car_brand: string;
  branch_store_id: string;
  branch_store_name: string;
  branch_store_address: string;
  valid: boolean;

  constructor(
    offer_id: string,
    price: string,
    car_brand: string,
    branch_store_id: string,
    branch_store_name: string,
    branch_store_address: string,
    valid: boolean
  ) {
    this.offer_id = offer_id;
    this.price = price;
    this.car_brand = car_brand;
    this.branch_store_id = branch_store_id;
    this.branch_store_name = branch_store_name;
    this.branch_store_address = branch_store_address;
    this.valid = valid;
  }
}

export interface IBookAppointment {
  firstname: string;
  lastname: string;
  email: string;
  licensePlate: string;
  tracking_offer_id: string;
  tracking_ng_id: string;
  isTip: boolean;
}

export interface IBookAppointmentRequest {
  offer_id: string;
  ng_id: string;
  appointment_start: string;
  appointment_end: string;
  payload: IBookAppointment;
}

export interface ISuccessResponse {
  appointment_end: string;
  appointment_start: string;
  branchstore_address: string;
  branchstore_name: string;
  car_brand: string;
  customer_name: string;
  offer_id: string;
  price: string;
  success_id: string;
}

export interface IBookingResponse {
  success_id: string;
  offer_id: string;
}