import { CommonModule, NgForOf, NgIf } from "@angular/common";
import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormsModule } from "@angular/forms";
import { SDialog, ShieldModule } from "@shield/angular";
import { take } from "rxjs";
import {
  IPartnerLocation,
  ITimeslot,
  ITimeslots,
  SimplePartnerLocation,
  Timeslot,
} from "../../model/partner.model";
import { DataShareService } from "../../service/data-share.service";
import { GoogleMapsService } from "../../service/google-maps.service";
import { PartnerService } from "../../service/partner.service";
import { SelectLocationComponent } from "../select-location/select-location.component";

@Component({
  selector: "app-location-finder",
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SelectLocationComponent,
    ShieldModule,
    NgForOf,
    NgIf,
  ],
  templateUrl: "./location-finder.component.html",
  styleUrl: "./location-finder.component.scss",
})
export class LocationFinderComponent implements OnInit, AfterViewInit {
  @ViewChildren("selectLocationDialog", { read: ElementRef })
  selectLocationDialog?: QueryList<SDialog>;
  public availableDays: string[] = [];
  public availableSlotsOfDay: ITimeslot[] = [];
  public daySelected = false;
  public isGoogleMapsLoaded = false;
  public isInitialTimeslotLoading = true;
  public isPartnerInformationLoaded = false;
  public isTimeslotInformationLoaded = false;
  public loadingTimeslots: boolean = false;
  public moreTimeslotsAvailable: boolean = false;
  public selectedTimeslot: Timeslot | null = null;
  public selectLocationDialogHeadline = "HUK-Ankaufstationen";
  public showSelectLocationDialog: boolean = false;
  public timeslots: ITimeslots | null = null;
  public timeslotSeverity: string = "";
  public readonly partnerService = inject(PartnerService);
  protected offerPartner: SimplePartnerLocation | null = null;
  private selectedDay: string | null = null;
  private selectedPartnerLocation: IPartnerLocation | null = null;
  private readonly destroyRef = inject(DestroyRef);
  private readonly dataShareService = inject(DataShareService);
  private readonly googleMapsService = inject(GoogleMapsService);

  constructor() {}

  public ngOnInit(): void {
    this.initMap();
    this.getPartnerInformation();
    this.initTimeSlotData();
    this.getTimeslots();
    this.subscribeToTimeslotValid();
    this.subscribeToPartnerInformationLoaded();
    this.subscribeToTimeslotInformationLoaded();
  }

  public ngAfterViewInit(): void {
    this.selectLocationDialog?.changes
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((changes) => {
        if (changes._results.length > 0) {
          (
            document.querySelector(
              "#selectLocationDialog"
            ) as HTMLSDialogElement
          ).open();
        }
      });
  }

  public applyNewLocation(): void {
    if (this.selectedPartnerLocation) {
      this.loadingTimeslots = true;
      this.partnerService.setCurrentPartner({
        id: this.selectedPartnerLocation.branch_store_id.toString(),
        name: this.selectedPartnerLocation.name,
        address: this.selectedPartnerLocation.address,
      });
      this.partnerService.isTimeslotInformationLoaded$
        .pipe(take(2))
        .subscribe((isLoaded) => {
          if (isLoaded) {
            this.closeSelectLocationDialog();
          }
        });
    }
  }

  public formatDate(date: string): string {
    return new Date(date).toLocaleDateString("de-DE", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  public onSelectLocationDialogCloseClick(): void {
    this.closeSelectLocationDialog();
  }

  public onSelectedPartnerLocationChange(event: IPartnerLocation): void {
    this.selectedPartnerLocation = event;
  }

  /**
   * Sets availableSlotsOfDay to hold the actual timeslots of this day.
   */
  public setSlotsForDay(selectedOption: any): void {
    this.selectedTimeslot = null;
    this.partnerService.setIsTimeslotSelected(false);
    const index = parseInt(selectedOption.split("-")[1]); //transforms 'value-3' to 3
    const date = this.availableDays[index];
    if (this.timeslots != null) {
      this.daySelected = true;
      this.selectedDay = date;
      this.availableSlotsOfDay = this.timeslots[date].slice(0, 8);
      if (this.timeslots[date].length > 8) {
        this.moreTimeslotsAvailable = true;
      }
      this.dataShareService.selectedDay = this.formatDate(date);
    }
  }

  public formatTimeslot(start: string, end: string) {
    const formattedStart = new Date(start).toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedEnd = new Date(end).toLocaleTimeString("de-DE", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedTimeslot = formattedStart + "-" + formattedEnd;
    this.dataShareService.selectedTimeslot = formattedTimeslot;
    return formattedTimeslot;
  }

  public loadMoreTimeslots() {
    if (this.timeslots == null || this.selectedDay == null) {
      this.moreTimeslotsAvailable = false;
      return;
    }
    const remainingElements =
      this.timeslots[this.selectedDay].length - this.availableSlotsOfDay.length;
    if (remainingElements >= 8) {
      this.availableSlotsOfDay.push(
        ...this.timeslots[this.selectedDay].slice(
          this.availableSlotsOfDay.length,
          this.availableSlotsOfDay.length + 8
        )
      );
    } else if (remainingElements > 0) {
      this.availableSlotsOfDay.push(
        ...this.timeslots[this.selectedDay].slice(
          this.availableSlotsOfDay.length,
          this.availableSlotsOfDay.length + remainingElements
        )
      );
      this.moreTimeslotsAvailable = false;
    } else {
      this.moreTimeslotsAvailable = false;
    }
  }

  public selectTimeslot(i: number) {
    this.selectedTimeslot = this.availableSlotsOfDay[i];
    this.timeslotSeverity = "";
    this.formatTimeslot(this.selectedTimeslot.start, this.selectedTimeslot.end);
    this.partnerService.setCurrentTimeslot(this.selectedTimeslot);
    this.partnerService.setIsTimeslotSelected(true);
    this.partnerService.setIsTimeslotValid(true);
  }

  public isTimeslotSelected(id: number) {
    return this.selectedTimeslot == this.availableSlotsOfDay[id];
  }

  /**
   * Usage of *ngIf to force rerendering of dialog component.
   */
  public openSelectLocationDialog(): void {
    this.showSelectLocationDialog = true;
  }

  /**
   * Usage of *ngIf to force rerendering of dialog component -> setTimout to let first handle shield logic and then destroy it inside frontend (scrolling issue, when not using setTimeout).
   * Timeout has no impact of user experience, because shield logic is executed first -> Dialog is closing immediately.
   */
  private closeSelectLocationDialog(): void {
    (
      document.querySelector("#selectLocationDialog") as HTMLSDialogElement
    ).close();
    setTimeout(() => {
      this.showSelectLocationDialog = false;
    }, 1000);
  }

  private getPartnerInformation(): void {
    this.partnerService.currentPartner$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((offerPartner) => {
        if (offerPartner) {
          this.offerPartner = offerPartner;
        }
      });
  }

  private getTimeslots(): void {
    this.partnerService.availableTimeslots$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        if (data && Object.keys(data).length > 0) {
          this.timeslots = data;
          this.availableDays = Object.keys(this.timeslots);
          const date = this.availableDays[0];
          this.daySelected = true;
          this.selectedDay = date;
          this.availableSlotsOfDay = this.timeslots[date].slice(0, 8);
          if (this.timeslots[date].length > 8) {
            this.moreTimeslotsAvailable = true;
          }
          this.dataShareService.selectedDay = this.formatDate(date);
          this.loadingTimeslots = false;
        } else {
          this.initTimeSlotData();
          this.loadingTimeslots = false;
        }
      });
  }

  private initMap() {
    this.googleMapsService
      .initMapsApi()
      .pipe(take(1))
      .subscribe((result) => {
        this.isGoogleMapsLoaded = result;
      });
  }

  private initTimeSlotData(): void {
    this.selectedDay = null;
    this.selectedTimeslot = null;
    this.moreTimeslotsAvailable = false; //resetting
    this.daySelected = false;
    this.availableSlotsOfDay = []; //reset to avoid showing dates of another partner
    if (this.timeslots != null) {
      this.availableDays = Object.keys(this.timeslots);
    }
  }

  private subscribeToPartnerInformationLoaded(): void {
    this.partnerService.isPartnerInformationLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLoaded) => {
        this.isPartnerInformationLoaded = isLoaded;
      });
  }

  private subscribeToTimeslotInformationLoaded(): void {
    this.partnerService.isTimeslotInformationLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLoaded) => {
        this.isTimeslotInformationLoaded = isLoaded;
      });
  }

  private subscribeToTimeslotValid() {
    this.partnerService.isTimeslotValid$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isTimeslotValid) => {
        this.timeslotSeverity = "";
        if (!isTimeslotValid) {
          this.timeslotSeverity = "critical";
        }
      });
  }
}
