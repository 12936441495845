import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { IStammdaten } from "../model/stammdaten.model";

@Injectable({
  providedIn: "root",
})
export class StammdatenService {
  protected basePath = environment.BASE_PATH_AUTHORIZED;

  constructor(private http: HttpClient) {}

  public requestVerification(
    offerId: string,
    birthdate: string,
    zip: string
  ): Observable<string | boolean> {
    let params = new HttpParams();
    params = params.append("offer_id", offerId);
    params = params.append("birthdate", encodeURIComponent(birthdate));
    params = params.append("zip_code", encodeURIComponent(zip));
    return this.http
      .get<string>(this.basePath + "/soft-auth", {
        params,
        observe: "response",
        withCredentials: true,
      })
      .pipe(
        map((response) => {
          if (response.status === 200) {
            return true;
          }
          return false;
        })
      );
  }

  public getStammdaten(offerId: string): Observable<IStammdaten> {
    let params = new HttpParams();
    params = params.append("offer_id", offerId);

    return this.http.get<IStammdaten>(this.basePath + "/stammdaten", {
      params,
    });
  }

  public logout(offerId: string): Observable<unknown> {
    let params = new HttpParams();
    params = params.append("offer_id", offerId);

    return this.http.get(this.basePath + "/logout", { params });
  }
}
