import { CommonModule, NgOptimizedImage } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ShieldModule } from "@shield/angular";
import { UtilityService } from "../service/utility.service";

@Component({
  selector: "app-next-steps",
  standalone: true,
  imports: [CommonModule, ShieldModule, NgOptimizedImage],
  templateUrl: "./next-steps.component.html",
  styleUrl: "./next-steps.component.scss",
})
export class NextStepsComponent {
  @Input() customerCarBrand: string | null = null;
  @Input() firstStepAsNumber = true;
  showDialog: boolean = false;

  constructor(protected utilityService: UtilityService) {}

  openPriceInfo() {
    this.showDialog = true;
  }

  closePriceInfo() {
    this.showDialog = false;
  }
}
