import { CommonModule, DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { ShieldModule } from "@shield/angular";
import { take } from "rxjs";
import { CampaignOverComponent } from "../campaign-over/campaign-over.component";
import { CrossSellingComponent } from "../cross-selling/cross-selling.component";
import { SimplePartnerLocation } from "../model/partner.model";
import { NextStepsComponent } from "../next-steps/next-steps.component";
import { CookieBannerService } from "../service/cookie-banner.service";
import { DataShareService } from "../service/data-share.service";
import { PartnerService } from "../service/partner.service";
import { ConditionsComponent } from "./conditions/conditions.component";
import { CustomerForm } from "./customer-form/customer-form.component";
import { HeroComponent } from "./hero/hero.component";
import { LocationFinderComponent } from "./location-finder/location-finder.component";
import { PriceCalculationComponent } from "./price-calculation/price-calculation.component";
import {datadogRum} from "@datadog/browser-rum";

@Component({
  selector: "app-landingpage",
  standalone: true,
  imports: [
    CampaignOverComponent,
    CommonModule,
    ConditionsComponent,
    CrossSellingComponent,
    CustomerForm,
    HeroComponent,
    LocationFinderComponent,
    NextStepsComponent,
    PriceCalculationComponent,
    ShieldModule,
  ],
  templateUrl: "./landingpage.component.html",
  styleUrl: "./landingpage.component.scss",
})
export class LandingpageComponent implements OnInit, AfterViewInit {
  @ViewChild("loadingContainer") loadingContainer!: ElementRef;
  public carBrand: string | null = null;
  public price: string | null = null;
  public isOfferDataLoading: boolean = false;
  public isPartnerLocationDataLoading: boolean = false;
  public isTimeslotDataLoading: boolean = false;
  public offerIsValid = true;
  offerId: string = "";
  public isTimeslotSelected = false;
  private readonly cookieBannerService = inject(CookieBannerService);
  private readonly destroyRef = inject(DestroyRef);

  public constructor(
    @Inject(DOCUMENT) private document: Document,
    private partnerService: PartnerService,
    private dataShareService: DataShareService,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    this.isOfferDataLoading = true;
    this.isPartnerLocationDataLoading = true;
    this.isTimeslotDataLoading = true;
    this.router.queryParamMap.pipe(take(1)).subscribe((queryParamMap) => {
      const offerId = queryParamMap.get("offer_id");
      const ng_id = queryParamMap.get("ID_NG");
      if (offerId != null) {
        this.offerId = offerId;
        this.partnerService.setCurrentOfferId(offerId);
        this.subscribeToOfferData(offerId);
        this.subscribeToPartnerLocations();
        this.subscribeToIsTimeslotInformationLoaded();
        this.subscribeToTimeslotSelected();
        if (ng_id != null) {
          this.dataShareService.ng_id = ng_id;
        }
      } else {
        //no param!
        this.document.location.href = "https://www.huk24.de/404-fehler";
      }
    });
  }

  public ngAfterViewInit(): void {
    if (this.loadingContainer) {
      const headerHeight = document.querySelector(".header")?.clientHeight;
      const footerHeight = document.querySelector(".footer")?.clientHeight;
      if (headerHeight && footerHeight) {
        const height = innerHeight - headerHeight - footerHeight - 66; // 66px from footer wrapper padding
        this.loadingContainer.nativeElement.style.height = `${height}px`;
      }
    }
  }

  private checkCookie() {
    if (!localStorage.getItem("privacy_consent")) {
    this.cookieBannerService.showCookieBanner();
    } else {
      if (localStorage.getItem("privacy_consent") === "true") {
        datadogRum.setTrackingConsent('granted');
      } else {
        datadogRum.setTrackingConsent('not-granted');
      }
    }
  }

  private subscribeToOfferData(offerId: string) {
    this.partnerService
      .getOffer(offerId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.carBrand = data.car_brand;
        this.price = data.price;
        this.offerIsValid = data.valid;
        const branch_store_id = data.branch_store_id;
        const branch_store_name = data.branch_store_name;
        const branch_store_address = data.branch_store_address;
        this.partnerService.setCurrentPartner(
          new SimplePartnerLocation(
            branch_store_id,
            branch_store_name,
            branch_store_address
          )
        );
        this.dataShareService.carBrand = this.carBrand;
        this.dataShareService.price = this.price;
        this.isOfferDataLoading = false;
      });
  }

  private subscribeToPartnerLocations() {
    this.partnerService
      .getPartnerLocations()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        if (data) {
          this.partnerService.setPartnerLocations(data);
          this.isPartnerLocationDataLoading = false;
        }
      });
  }

  private subscribeToIsTimeslotInformationLoaded(): void {
    this.partnerService.isTimeslotInformationLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLoaded) => {
        if (isLoaded) {
          this.isTimeslotDataLoading = false;
          this.checkCookie();
        }
      });
  }

  private subscribeToTimeslotSelected() {
    this.partnerService.isTimeslotSelected$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((timeslot) => {
        this.isTimeslotSelected = timeslot;
        if (timeslot) {
          setTimeout(() => {
            this.document
              .querySelector("#customerForm")
              ?.scrollIntoView({ behavior: "smooth", block: "start" });
          }, 50);
        }
      });
  }
}
