<div
  class="wrapper"
  *ngIf="
    !isOfferDataLoading &&
      !isPartnerLocationDataLoading &&
      !isTimeslotDataLoading;
    else loadingTemplate
  "
>
  <ng-container *ngIf="offerIsValid; else campaign_over">
    <app-hero
      *ngIf="price && carBrand"
      [customerCarBrand]="carBrand"
      [customerCarOffer]="price"
    ></app-hero>
    <app-location-finder></app-location-finder>
    <app-customer-form
      [offerId]="offerId"
      #customerForm
      id="customerForm"
    ></app-customer-form>
    <app-price-calculation></app-price-calculation>
    <app-next-steps
      *ngIf="carBrand"
      [customerCarBrand]="carBrand"
    ></app-next-steps>
    <app-cross-selling class="s:pb-2"></app-cross-selling>
    <app-conditions></app-conditions>
  </ng-container>
  <ng-template #campaign_over>
    <app-campaign-over></app-campaign-over>
  </ng-template>
</div>
<ng-template #loadingTemplate>
  <div
    #loadingContainer
    class="loading-container s:d-flex s:flex-col s:gap-6 s:justify-center s:items-center"
  >
    <s-progress-circular
      color="accent"
      indeterminate="true"
      size="xxl"
    ></s-progress-circular>
    <p class="s:text-subtitle s:text-bold s:text-center">
      Persönliches Angebot wird geladen
    </p>
  </div>
</ng-template>
