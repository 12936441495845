<div class="wrapper">
    <div class="footer s:pt-6 s:pr-4 s:pb-6 s:pl-4">
        <div class="top s:text-body-1 s:text-bold">
            <a target="_blank" href="/impressum" class="s:text">Impressum</a>
            <img src="assets/ellipse.svg" class="ellipse" alt="Punkt">
            <a target="_blank" href="/datenschutz" class="s:text">Datenschutz</a>
            <img src="assets/ellipse.svg" class="ellipse" alt="Punkt">
            <a target="_blank" href="/privatsphaere-einstellungen" class="s:text">Privatsphäre-Einstellungen</a>
        </div>
        <div class="bottom s:pt-6 ">
            <p class="s:text-caption s:text-neutral">© 2024 HUK-COBURG Digitale Services GmbH</p>
        </div>
    </div>
</div>