<div class="container">
  <div class="header">
    <h3>Daten des Fahrzeughalters</h3>
  </div>
  <s-divider></s-divider>
  <div class="s:py-6 s:px-4">
    <p>
      Es werden die Daten des Fahrzeughalters benötigt. Tragen Sie hier Ihre
      Daten ein oder nutzen Sie die Verifikation um Ihre Daten automatisch
      eintragen zu lassen.
    </p>
    <s-button
      class="verify-button s:my-4"
      variant="outlined"
      (click)="openDialog()"
    >
      <s-icon name="person" size="md" variant="outlined"></s-icon>
      Meine Daten übernehmen
    </s-button>

    <form
      class="form"
      [formGroup]="customerFormGroup"
      (ngSubmit)="submitForm()"
    >
      <div class="row">
        <s-text-field
          class="s:mb-2"
          fullWidth="true"
          severity="{{ formElementSeverity[0] }}"
          type="text"
          formControlName="lastName"
        >
          <span slot="label"><b>Nachname</b></span>
          <span *ngIf="formElementSeverity[0] == 'critical'" slot="helptext"
            >Bitte geben Sie Ihren Nachnamen ein</span
          >
        </s-text-field>
        <s-text-field
          class="s:mb-2"
          fullWidth="true"
          severity="{{ formElementSeverity[1] }}"
          type="text"
          formControlName="firstname"
        >
          <span slot="label"><b>Vorname</b></span>
          <span *ngIf="formElementSeverity[1] == 'critical'" slot="helptext"
            >Bitte geben Sie Ihren Vornamen ein</span
          >
        </s-text-field>
      </div>
      <div class="custom-row-wrapper">
        <s-text-field
          class="s:mb-2"
          fullWidth="true"
          severity="{{ formElementSeverity[2] }}"
          type="text"
          input-type="email"
          formControlName="email"
        >
          <span slot="label"><b>E-Mail</b></span>
          <span *ngIf="formElementSeverity[2] == 'critical'" slot="helptext"
            >Bitte geben Sie Ihre E-Mail ein</span
          >
        </s-text-field>
        <div class="license-plate-wrapper row">
          <s-text-field
            class="s:mb-4 s:p-0"
            fullWidth="true"
            maxlength="3"
            severity="{{ formElementSeverity[3] }}"
            type="text"
            formControlName="locationCode"
          >
            <span class="s:text-nowrap" slot="label"
              ><b>KFZ-Kennzeichen</b></span
            >
            <span slot="helptext">
              <p *ngIf="formElementSeverity[3] == 'critical'">
                Ortskennzeichen
              </p>
              <p class="s:text-subdued">Beispiel: M HU240</p>
            </span>
          </s-text-field>
          <s-text-field
            class="s:mb-4"
            fullWidth="true"
            severity="{{ formElementSeverity[4] }}"
            type="text"
            formControlName="identificationNumber"
          >
            <span class="s:text-nowrap text-transparent" slot="label"
              ><b>Test</b></span
            >
            <span *ngIf="formElementSeverity[4] == 'critical'" slot="helptext"
              >Erkennungsnummer</span
            >
          </s-text-field>
        </div>
      </div>
      <s-checkbox
        severity="{{ formElementSeverity[5] }}"
        formControlName="privacyPolicy"
        ><span slot="label"
          >Ich habe zur Kenntnis genommen, dass mit dem Klick auf den Button
          „Kostenfreien Termin Buchen“ eine Terminbuchung zur Fahrzeugbewertung
          bei der HUK-COBURG Autowelt GmbH durchgeführt wird. Zu diesem Zweck
          werden meine Daten von HUK-Coburg Digitale Services GmbH an die
          HUK-COBURG Autowelt GmbH übermittelt. Weitere Informationen finden
          sich in der
          <a target="_blank" href="/datenschutz"
            ><b class="datenschutz">Datenschutzerklärung</b></a
          >.</span
        >
        <span *ngIf="formElementSeverity[5] == 'critical'" slot="helptext"
          >Bestätigung notwendig</span
        >
      </s-checkbox>
    </form>
    <s-button
      class="submit_button s:my-4"
      (click)="submitForm()"
      loading="{{ loading }}"
    >
      <s-icon name="calendar-month" size="md" variant="outlined"></s-icon>
      Kostenfreien Termin buchen
    </s-button>
    <p class="s:text-neutral s:text-regular s:text-body-2">
      Dies ist ein Angebot unseres Partners, der HUK-COBURG Autowelt GmbH.
    </p>
  </div>
</div>

<s-dialog
  close-button="true"
  dismissible="true"
  show="{{ showDialog }}"
  (sCloseDialog)="closeDialog()"
>
  <h6 class="dialog-heading" slot="prepend">Daten übernehmen</h6>
  <div class="dialog-text">
    Durch die Bestätigung Ihrer Identität können wir Ihre persönlichen Daten aus
    dem HUK24-Datenbestand übernehmen, um den Prozess für Sie zu
    vereinfachen.<br /><br />
    Die Übernahme der Daten hat keinen Einfluss auf Ihr aktuelles Angebot.
  </div>
  <form class="dialog-form" slot="append" [formGroup]="softAuthFormGroup">
    <s-text-field
      id="zip-verify"
      input-type="string"
      full-width="true"
      [severity]="zipSeverity"
      formControlName="zip"
    >
      <span slot="label">Postleitzahl</span>
      <span *ngIf="zipSeverity == 'critical'" slot="helptext"
        >Bitte überprüfen Sie die Postleitzahl</span
      >
    </s-text-field>
    <s-text-field
      id="date-verify"
      input-type="date"
      full-width="true"
      [severity]="birthdateSeverity"
      formControlName="birthdate"
    >
      <span slot="label">Geburtsdatum</span>
      <span *ngIf="birthdateSeverity == 'critical'" slot="helptext"
        >Bitte überprüfen Sie das Geburtsdatum</span
      >
    </s-text-field>
    <menu>
      <s-button
        class="s:mt-2 s:mb-2"
        full-width="true"
        loading="{{ verificationIsLoading }}"
        variant="filled"
        type="submit"
        (click)="verifyUser()"
        >Daten übernehmen
      </s-button>
      <s-button
        class="s:mt-2 s:mb-2"
        full-width="true"
        variant="outlined"
        (click)="closeDialog()"
      >
        Abbrechen
      </s-button>
    </menu>
  </form>
</s-dialog>
