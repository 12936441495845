import { NgIf } from "@angular/common";
import { Component, DestroyRef, inject, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ShieldModule } from "@shield/angular";
import {
  IBookAppointment,
  IBookAppointmentRequest,
  ITimeslot,
} from "../../model/partner.model";
import { IStammdaten } from "../../model/stammdaten.model";
import { NextStepsComponent } from "../../next-steps/next-steps.component";
import { DataShareService } from "../../service/data-share.service";
import { PartnerService } from "../../service/partner.service";
import { StammdatenService } from "../../service/stammdaten.service";

@Component({
  selector: "app-customer-form",
  standalone: true,
  imports: [ShieldModule, NextStepsComponent, ReactiveFormsModule, NgIf],
  templateUrl: "./customer-form.component.html",
  styleUrl: "./customer-form.component.scss",
})
export class CustomerForm implements OnInit {
  @Input() offerId: string | null = null;
  public birthdateSeverity: string = "";
  public customerFormGroup = new FormGroup({
    firstname: new FormControl("", Validators.required),
    lastName: new FormControl("", Validators.required),
    email: new FormControl("", [
      Validators.required,
      Validators.pattern("^[a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$"),
    ]),
    locationCode: new FormControl("", [Validators.required]),
    identificationNumber: new FormControl("", [Validators.required]),
    privacyPolicy: new FormControl<boolean | null>(null, Validators.required),
  });

  public softAuthFormGroup = new FormGroup({
    birthdate: new FormControl("", Validators.required),
    zip: new FormControl("", Validators.required),
  });

  public formElementSeverity: string[] = new Array<string>(
    "",
    "",
    "",
    "",
    "",
    ""
  );
  public showDialog: boolean = false;
  public verificationIsLoading: boolean = false;
  public zipSeverity: string = "";
  private isTimeslotSelected: boolean = false;
  loading: boolean = false;
  private previousLicensePlateLastPart: string = "";
  private readonly destroyRef = inject(DestroyRef);
  private readonly partnerService = inject(PartnerService);
  private readonly router = inject(Router);
  private readonly stammdatenService = inject(StammdatenService);
  private readonly datashareService = inject(DataShareService);

  public constructor() {}

  public ngOnInit() {
    this.subscribeToTimeslotSelected();
    this.subscribeToCustomerFormChanges();
    this.subscribeToSoftAuthChanges();
  }

  public closeDialog() {
    this.showDialog = false;
  }

  public openDialog() {
    this.showDialog = true;
  }

  public verifyUser() {
    if (this.softAuthFormGroup.invalid) {
      this.setSoftAuthValidity();
    } else {
      const birthdate = this.softAuthFormGroup.value.birthdate;
      const zip = this.softAuthFormGroup.value.zip;

      this.verificationIsLoading = true;
      const date = new Date(birthdate!);
      const year = date!.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${day}.${month}.${year}`;
      this.stammdatenService
        .requestVerification(this.offerId!, formattedDate, zip!)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (result) => {
            if (result) {
              this.loadUserMasterData();
            }
          },
          error: (error) => {
            this.birthdateSeverity = "critical";
            this.zipSeverity = "critical";
            this.verificationIsLoading = false;
            console.warn("Error during verification", error);
          },
          complete: () => {},
        });
    }
  }

  public submitForm(): void {
    if (
      this.customerFormGroup.valid &&
      this.isTimeslotSelected &&
      this.customerFormGroup.controls.email.valid
    ) {
      this.loading = true;
      const currentParnter = this.partnerService.currentPartner$.value;
      const currentTimeslot = this.partnerService.currentTimeslot$.value;
      const currentOfferId = this.partnerService.currentOfferId$.value;
      const currentNgId = this.datashareService.ng_id;
      if (currentParnter && currentTimeslot && currentOfferId) {
        const branchstore_id = currentParnter.id;
        const appointment_id = currentTimeslot.uid;
        const request = this.getBookAppointmentRequest(
          currentOfferId,
          currentNgId,
          currentTimeslot
        );
        this.partnerService
          .bookAppointment(branchstore_id, appointment_id, request)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((data) => {
            this.loading = false;
            this.router.navigate(["/success"], {
              queryParams: {
                offer_id: data.offer_id,
                success_id: data.success_id,
              },
            });
          });
      }
    } else {
      this.handleInvalidForm();
    }
  }

  private checkCustomerFormCriticalityOkay(
    element: FormControl<string | boolean | null>,
    index: number
  ): boolean {
    if (!element.value || !element.valid) {
      this.formElementSeverity[index] = "critical";
      return false;
    } else {
      this.formElementSeverity[index] = "";
      return true;
    }
  }

  private convertValueToUpperCase(control: FormControl): void {
    control.setValue(control.value.toUpperCase(), { emitEvent: false });
  }

  private getBookAppointmentRequest(
    offerId: string,
    ngId: string | null,
    timeslot: ITimeslot
  ): IBookAppointmentRequest {
    if (!ngId) {
      ngId = "";
    }
    return {
      offer_id: offerId,
      ng_id: ngId,
      appointment_start: timeslot.start,
      appointment_end: timeslot.end,
      payload: this.getUserPayload(),
    };
  }

  private getUserPayload(): IBookAppointment {
    let ngId = this.datashareService.ng_id;
    if (localStorage.getItem("privacy_consent") === "false") {  //optional tracking
      ngId = "";
    }
    return <IBookAppointment>{
      firstname: this.customerFormGroup.controls.firstname.value!,
      lastname: this.customerFormGroup.controls.lastName.value!,
      email: this.customerFormGroup.controls.email.value!,
      licensePlate: `${this.customerFormGroup.controls.locationCode
        .value!}-${this.customerFormGroup.controls.identificationNumber.value!.replace(
        /\s/g,
        ""
      )}`,
      tracking_offer_id: this.offerId,
      tracking_ng_id: ngId,
      isTip: false,
    };
  }

  private handleInvalidForm(): void {
    if (this.customerFormGroup.invalid) {
      this.checkCustomerFormCriticalityOkay(
        this.customerFormGroup.controls.firstname,
        1
      );
      this.checkCustomerFormCriticalityOkay(
        this.customerFormGroup.controls.lastName,
        0
      );
      this.checkCustomerFormCriticalityOkay(
        this.customerFormGroup.controls.email,
        2
      );
      this.checkCustomerFormCriticalityOkay(
        this.customerFormGroup.controls.locationCode,
        3
      );
      this.checkCustomerFormCriticalityOkay(
        this.customerFormGroup.controls.identificationNumber,
        4
      );
      this.checkCustomerFormCriticalityOkay(
        this.customerFormGroup.controls.privacyPolicy,
        5
      );
    }
    if (!this.isTimeslotSelected) {
      this.partnerService.setIsTimeslotValid(false);
    }
    setTimeout(() => {
      this.scrollToFirstError();
    }, 50);
  }

  private licensePlateFirstPartPattern(control: FormControl): void {
    const value = control.value as string;
    if (value && value.match(/[^A-ZÖÜÄ]/)) {
      const newValue = value.replace(/[^A-ZÖÜÄ]/g, "");
      setTimeout(() => {
        this.customerFormGroup.controls.locationCode.setValue(newValue, {
          emitEvent: false,
        });
      }, 50);
    }
  }

  private licensePlateLastPartPattern(control: FormControl): void {
    let value = control.value as string;
    const licensePlateFirstPartLength =
      this.customerFormGroup.controls.locationCode.value!.length;
    const allowedCharectersLength = 9 - licensePlateFirstPartLength;

    // Allow a maximum of ${allowedCharectersLength} characters
    if (value.length > allowedCharectersLength) {
      value = value.substring(0, allowedCharectersLength);
    }

    // Check if the new value is one character less (deletion case)
    const isDeletion = value.length < this.previousLicensePlateLastPart.length;

    if (!isDeletion) {
      // Remove all non-alphabetical characters
      value = value.replace(/[^A-Z0-9]/g, "");

      // Add a space after alphabetical characters
      value = value.replace(
        /^([A-ZÖÜÄ]{1,2})([A-ZÖÜÄ0-9]*)$/,
        (match, p1, p2) => {
          return p1 + " " + p2;
        }
      );

      // Remove space if followed by another alphabetical character and add it to the end
      value = value.replace(/^([A-ZÖÜÄ]{1,2}) ([A-ZÖÜÄ])/, (match, p1, p2) => {
        return p1 + p2;
      });

      // Allow a maximum of 2 alphabetical characters at the beginning
      value = value.replace(/^([A-ZÖÜÄ]{3,})(.*)$/, (match, p1, p2) => {
        return p1.substring(0, 2) + p2;
      });

      // After alphabetical characters, only accept numeric characters and a maximum of 4
      value = value.replace(
        /^([A-ZÖÜÄ]{1,2} )([0-9]{5,})(.*)$/,
        (match, p1, p2, p3) => {
          return p1 + p2.substring(0, 4);
        }
      );

      // Prevent alphabetical characters after the first number
      value = value.replace(
        /^([A-ZÖÜÄ]{1,2} [0-9]+)([A-ZÖÜÄ]+)(.*)$/,
        (match, p1, p2, p3) => p1 + p3
      );

      // Prevent alphabetical characters after a number
      if (/^[0-9]/.test(value)) {
        value = value.replace(/[^0-9]/g, "").substring(0, 4);
      }

      // If string starts with a number, only allow a total of 4 numbers and no alphabetical characters
      value = value.replace(/^([0-9]{4,})(.*)$/, (match, p1, p2) => {
        return p1.substring(0, 4);
      });
    }

    setTimeout(() => {
      this.customerFormGroup.controls.identificationNumber.setValue(value, {
        emitEvent: false,
      });
    }, 50);

    // Update the previous value
    this.previousLicensePlateLastPart = value;
  }

  private loadUserMasterData() {
    this.stammdatenService
      .getStammdaten(this.offerId!)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data) => {
          this.updateCustomerForm(data);
          this.verificationIsLoading = false;
          this.closeDialog();
          this.logout();
        },
        error: (error) => {
          this.birthdateSeverity = "critical";
          this.zipSeverity = "critical";
          this.verificationIsLoading = false;
          console.warn("Error loading stammdaten", error);
        },
        complete: () => {},
      });
  }

  private logout(): void {
    this.stammdatenService
      .logout(this.offerId!)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  private scrollToFirstError(): void {
    const elements = Array.from(
      document.querySelectorAll(
        "[severity='critical'], [ng-reflect-severity='critical']"
      )
    );

    if (elements.length > 0) {
      elements[0].scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  private setSoftAuthValidity() {
    if (!this.softAuthFormGroup.value.birthdate) {
      this.birthdateSeverity = "critical";
    } else {
      this.birthdateSeverity = "";
    }
    if (!this.softAuthFormGroup.value.zip) {
      this.zipSeverity = "critical";
    } else {
      this.zipSeverity = "";
    }
  }

  private subscribeToCustomerFormChanges() {
    const controls = [
      { control: this.customerFormGroup.controls.lastName, index: 0 },
      { control: this.customerFormGroup.controls.firstname, index: 1 },
      { control: this.customerFormGroup.controls.email, index: 2 },
      { control: this.customerFormGroup.controls.locationCode, index: 3 },
      {
        control: this.customerFormGroup.controls.identificationNumber,
        index: 4,
      },
      { control: this.customerFormGroup.controls.privacyPolicy, index: 5 },
    ];

    controls.forEach(({ control, index }) => {
      (control as FormControl).valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          if (control === this.customerFormGroup.controls.locationCode) {
            this.convertValueToUpperCase(control);
            this.licensePlateFirstPartPattern(control);
          }
          if (
            control === this.customerFormGroup.controls.identificationNumber
          ) {
            this.convertValueToUpperCase(control);
            this.licensePlateLastPartPattern(control);
          }
          this.checkCustomerFormCriticalityOkay(control, index);
        });
    });
  }

  private subscribeToSoftAuthChanges() {
    const controls = [
      { control: this.softAuthFormGroup.controls.zip, index: 0 },
      { control: this.softAuthFormGroup.controls.birthdate, index: 1 },
    ];

    controls.forEach(({ control, index }) => {
      (control as FormControl).valueChanges
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          if (control === this.softAuthFormGroup.controls.zip) {
            this.zipSeverity = "";
          }
          if (control === this.softAuthFormGroup.controls.birthdate) {
            this.birthdateSeverity = "";
          }
        });
    });
  }

  private subscribeToTimeslotSelected() {
    this.partnerService.isTimeslotSelected$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isTimeslotSelected) => {
        this.isTimeslotSelected = isTimeslotSelected;
      });
  }

  private updateCustomerForm(data: IStammdaten) {
    this.customerFormGroup.controls.firstname.setValue(data.first_name);
    this.customerFormGroup.controls.lastName.setValue(data.last_name);
    this.customerFormGroup.controls.email.setValue(data.mail);
    this.customerFormGroup.controls.locationCode.setValue(
      data.license_plate.split("-")[0]
    );
    this.customerFormGroup.controls.identificationNumber.setValue(
      data.license_plate.split("-")[1]
    );
  }
}
