<h1 class="s:text-h6 s:pb-4">Wie kommt der Preis zustande?</h1>
<p class="s:text-body-1">
    Dieses Ankaufsangebot stellt lediglich eine Schätzung des erzielbaren Kaufpreises dar. Dabei gehen wir von einem
    einwandfreien Fahrzeug aus, das alters- und laufleistungstypische Verschleißerscheinungen aufweist.
</p>
<p class="s:text-body-1 s:pt-4">
    Der tatsächliche Zustand und somit das verbindliche Ankaufsangebot kann erst vor Ort nach einer Begutachtung durch
    unsere Experten ermittelt werden.
</p>

