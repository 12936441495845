<div>
  <h2 class="s:text-subtitle s:text-bold">
    So läuft der Verkauf Ihres
    {{
      utilityService.generateGenitive(customerCarBrand)
    }}
    ab
  </h2>
  <div class="list">
    <div class="entry">
      <img
        *ngIf="firstStepAsNumber; else firstStepAsCheck"
        ngSrc="assets/steps/step1.svg"
        height="24"
        width="24"
        alt="Schritt 1"
      />
      <p class="s:text-body-1">
        <strong>Einfache Buchung:</strong> In 2 Minuten vereinbaren Sie einen Termin bei einer unserer qualifizierten Ankaufstation.
      </p>
    </div>

    <div class="entry">
      <img
        ngSrc="assets/steps/step2.svg"
        height="24"
        width="24"
        alt="Schritt 2"
      />
      <p class="s:text-body-1">
        <strong>Kostenloser Profi-Check:</strong> Unsere HUK-Bewerter vor Ort machen eine Bestandsaufnahme des Fahrzeugs.
      </p>
    </div>
    <div class="entry">
      <img
        ngSrc="assets/steps/step3.svg"
        height="24"
        width="24"
        alt="Schritt 3"
      />
      <p class="s:text-body-1">
        <strong>Gesicherter Autoverkauf:</strong> Wir kaufen Ihr Auto – unabhängig von Marke und Zustand, solange es auf eigenen Rädern zur Ankaufstation kommt.
        <s-icon
                class="info s:text s:cursor-pointer"
                size="xs"
                variant="outlined"
                name="info"
                (click)="openPriceInfo()"
        ></s-icon>
      </p>
    </div>
    <div class="entry">
      <img
        ngSrc="assets/steps/step4.svg"
        height="24"
        width="24"
        alt="Schritt 4"
      />
      <p class="s:text-body-1">
        <strong>Ausreichend Bedenkzeit:</strong> Sie können sofort verkaufen.
        Sind Sie sich noch unsicher? Kein Problem. Unser Angebot ist 7 Tage
        gültig.
      </p>
    </div>
    <div class="entry">
      <img
        ngSrc="assets/steps/step5.svg"
        height="24"
        width="24"
        alt="Schritt 5"
      />
      <p class="s:text-body-1">
        <strong>Schnelle Abwicklung:</strong> Der Verkauf dauert max. 45
        Minuten, die Überweisung erfolgt binnen 3 Werktagen, und die Abmeldung
        übernehmen wir kostenlos.
      </p>
    </div>
  </div>
</div>

<ng-template #firstStepAsCheck>
  <s-icon
    slot="iconStartInner"
    size="md"
    variant="filled"
    name="check-circle"
  ></s-icon>
</ng-template>


<s-dialog
        close-button="true"
        dismissible="true"
        show="{{ showDialog }}"
        (sCloseDialog)="closePriceInfo()"
>
    <h6 slot="prepend">Mindestzustand des Fahrzeuges</h6>
    <div>
        Im Einzelfall können wir kein Ankaufsangebot abgeben. Dies würde in folgenden Fällen auftreten: Wenn das
        Fahrzeug einen unreparierten Unfallschaden aufweist, der TÜV aktuell abgelaufen ist, es sich um ein
        Importfahrzeug aus NON-EU Ländern handelt, die Haltedauer unter 3 Monaten liegt, eine Kontrollleuchte dauerhaft
        leuchtet oder offensichtliche Probleme an Motor und Getriebe festgestellt werden.
    </div>
</s-dialog>