<div class="wrapper" *ngIf="isSuccessDataLoaded; else loadingTemplate">
  <div class="top-section">
    <div class="heading">
      <img src="assets/checkmark-highlight.png" alt="Erfolg" />
      <h5>
        {{ dataShareService.customerName }}, Ihr Termin wurde erfolgreich
        gebucht
      </h5>
    </div>
    <p>
      Sie erhalten innerhalb der nächsten 24 Stunden eine Bestätigungs-E-Mail
      von der HUK Autowelt. In dieser E-Mail finden Sie alle wichtigen
      Informationen zu Ihrem Termin.
    </p>
  </div>
  <app-summary></app-summary>
  <p>
    Hinweis: Sie erhalten innerhalb der nächsten 24 Stunden eine
    Bestätigungs-E-Mail von der HUK Autowelt. In dieser E-Mail finden Sie alle
    wichtigen Informationen zu Ihrem Termin.
  </p>
  <app-next-steps
    *ngIf="dataShareService.carBrand"
    [customerCarBrand]="dataShareService.carBrand"
    [firstStepAsNumber]="false"
  ></app-next-steps>
  <app-support></app-support>
  <app-cross-selling></app-cross-selling>
</div>
<ng-template #loadingTemplate>
  <div
    #loadingContainer
    class="loading-container s:d-flex s:flex-col s:gap-6 s:justify-center s:items-center"
  >
    <s-progress-circular
      color="accent"
      indeterminate="true"
      size="xxl"
    ></s-progress-circular>
    <p class="s:text-subtitle s:text-bold s:text-center">
      Terminbestätigung wird geladen
    </p>
  </div>
</ng-template>
