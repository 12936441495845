import { NgForOf, NgIf } from "@angular/common";
import { Component } from "@angular/core";
import { ShieldModule } from "@shield/angular";
import { DataShareService } from "../../service/data-share.service";
import { UtilityService } from "../../service/utility.service";

@Component({
  selector: "app-summary",
  standalone: true,
  imports: [NgForOf, NgIf, ShieldModule],
  templateUrl: "./summary.component.html",
  styleUrl: "./summary.component.scss",
})
export class SummaryComponent {
  constructor(
    protected utilityService: UtilityService,
    protected dataShareService: DataShareService
  ) {}
}
