import { CommonModule } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  OnInit,
  Output,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { GoogleMapsModule } from "@angular/google-maps";
import { ShieldModule } from "@shield/angular";
import { BehaviorSubject, combineLatest, of, switchMap } from "rxjs";
import {
  IPartnerLocation,
  SimplePartnerLocation,
} from "../../model/partner.model";
import { PartnerService } from "../../service/partner.service";

export interface IAdvancedMarkerElementOptions {
  content?: unknown;
  position: google.maps.LatLngLiteral;
  selected: boolean;
  title?: string;
}

@Component({
  selector: "app-select-location",
  standalone: true,
  imports: [CommonModule, ShieldModule, GoogleMapsModule],
  templateUrl: "./select-location.component.html",
  styleUrl: "./select-location.component.scss",
})
export class SelectLocationComponent implements OnInit {
  @Output() selectedPartnerLocation = new EventEmitter<IPartnerLocation>();
  public center: google.maps.LatLngLiteral = { lat: 0, lng: 0 };
  public zoom = 0;
  public googleMapsMarker =
    new BehaviorSubject<Array<IAdvancedMarkerElementOptions> | null>(null);

  public googleMapsOptions = new BehaviorSubject<google.maps.MapOptions | null>(
    null
  );

  public isPartnerInformationLoaded = false;
  public isTimeslotInformationLoaded = false;

  protected offerPartner: SimplePartnerLocation | null = null;
  protected partnerLocations: Array<IPartnerLocation> | null = null;
  private mapMarkerDesktopSelected: string | null = null;
  private mapMarkerDesktopUnselected: string | null = null;
  private mapMarkerMobileSelected: string | null = null;
  private mapMarkerMobileUnselected: string | null = null;
  private readonly destroyRef = inject(DestroyRef);
  private readonly httpClient = inject(HttpClient);
  private readonly partnerService = inject(PartnerService);

  public ngOnInit(): void {
    this.loadIcons();
    this.subscribeToPartnerInformationLoaded();
    this.subscribeToTimeslotInformationLoaded();
  }

  public setDialogContentHeight(): void {
    const dialogContent = document.querySelector(".dialog-container");
    const innerHeight = window.innerHeight;
    const dialogHeader = document.querySelector(
      ".select-location-dialog > .s-dialog__dialog .s-dialog__prepend"
    );
    const dialogFooterHeader = document.querySelector(
      ".select-location-dialog > .s-dialog__dialog .s-dialog__append"
    );

    if (innerHeight && dialogHeader && dialogFooterHeader) {
      const dialogContentHeight =
        innerHeight -
        dialogHeader.clientHeight -
        dialogFooterHeader.clientHeight;
      dialogContent?.setAttribute(
        "style",
        `height: ${
          !this.isMobile()
            ? dialogContentHeight - innerHeight * 0.1
            : dialogContentHeight
        }px`
      );
    }
  }

  public onMarkerClick(marker: IAdvancedMarkerElementOptions) {
    const location = this.partnerLocations!.find(
      (loc) =>
        loc.location.latitude === marker.position.lat &&
        loc.location.longitude === marker.position.lng
    );
    if (location) {
      this.selectLocation(location);
      this.updateMapMarkers(location);
      this.updateSelectedPartnerLocation(location);
    }
  }

  public selectLocation(location: IPartnerLocation) {
    this.selectedPartnerLocation.emit(location);
    this.center = {
      lat: location.location.latitude,
      lng: location.location.longitude,
    };
    this.updateMapMarkers(location);
    this.updateSelectedPartnerLocation(location);
    setTimeout(() => {
      this.scrollToSelectedLocation();
    }, 50);
  }

  private getCoordinatesOfOfferPartner(): { lat: number; lng: number } {
    if (this.offerPartner) {
      const partnerLocation = this.partnerLocations?.find(
        (location) => location.branch_store_id === +this.offerPartner!.id
      );
      if (partnerLocation) {
        return {
          lat: partnerLocation.location.latitude,
          lng: partnerLocation.location.longitude,
        };
      }
    }
    return { lat: 0, lng: 0 };
  }

  private getMapMarker(selected?: boolean): Element {
    const parser = new DOMParser();
    return parser.parseFromString(
      selected
        ? this.isMobile()
          ? this.mapMarkerMobileSelected!
          : this.mapMarkerDesktopSelected!
        : this.isMobile()
        ? this.mapMarkerMobileUnselected!
        : this.mapMarkerDesktopUnselected!,
      "image/svg+xml"
    ).documentElement;
  }

  private getPartnerInformation(): void {
    this.partnerService.currentPartner$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((currentPartner) => {
          return combineLatest([
            of(currentPartner),
            this.partnerService.partnerLocations$.pipe(
              takeUntilDestroyed(this.destroyRef)
            ),
          ]);
        })
      )
      .subscribe(([offerPartner, partnerLocations]) => {
        if (offerPartner && partnerLocations) {
          this.offerPartner = offerPartner;
          this.partnerLocations = partnerLocations;
          this.setDialogContentHeight();
          this.initMapOptions();
        }
      });
  }

  private getPositions(): Array<{
    lat: number;
    lng: number;
    selected?: boolean;
  }> {
    return this.partnerLocations!.map((location) => {
      return {
        lat: location.location.latitude,
        lng: location.location.longitude,
        selected: location.selected,
      };
    });
  }

  private initMapMarkers() {
    if (this.partnerLocations) {
      this.initSelectedPartnerLocation();
      const positions = this.getPositions();
      if (positions.length > 0) {
        this.googleMapsMarker.next(
          positions.map((position) => {
            return {
              content: this.getMapMarker(position.selected),
              position,
              selected: position.selected ? true : false,
              title: "Location",
            };
          })
        );
      }
    }
  }

  private initMapOptions() {
    const offerPartnerCoords = this.getCoordinatesOfOfferPartner();
    const mapsOptions: google.maps.MapOptions = {
      center: offerPartnerCoords,
      disableDefaultUI: true,
      mapId: "a837888af8bcbb8c",
      zoom: 9,
      gestureHandling: "greedy",
    };
    this.center = offerPartnerCoords;
    this.zoom = mapsOptions.zoom!;
    this.googleMapsOptions.next(mapsOptions);
    this.initMapMarkers();
  }

  private initSelectedPartnerLocation() {
    this.partnerLocations!.forEach((location) => {
      location.selected = location.branch_store_id === +this.offerPartner!.id;
    });
    this.selectLocation(this.partnerLocations!.find((loc) => loc.selected)!);
  }

  private isMobile(): boolean {
    return window.innerWidth < 768;
  }

  private loadIcons() {
    this.httpClient
      .get("assets/map_marker_desktop_unselected.svg", { responseType: "text" })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap((mapMarkerUnselected) => {
          return combineLatest([
            of(mapMarkerUnselected),
            this.httpClient
              .get("assets/map_marker_desktop_selected.svg", {
                responseType: "text",
              })
              .pipe(takeUntilDestroyed(this.destroyRef)),
            this.httpClient
              .get("assets/map_marker_mobile_unselected.svg", {
                responseType: "text",
              })
              .pipe(takeUntilDestroyed(this.destroyRef)),
            this.httpClient
              .get("assets/map_marker_mobile_selected.svg", {
                responseType: "text",
              })
              .pipe(takeUntilDestroyed(this.destroyRef)),
          ]);
        })
      )
      .subscribe(
        ([
          mapMarkerDesktopUnselected,
          mapMarkerDesktopSelected,
          mapMarkerMobileUnselected,
          mapMarkerMobileSelected,
        ]) => {
          this.mapMarkerDesktopUnselected = mapMarkerDesktopUnselected;
          this.mapMarkerDesktopSelected = mapMarkerDesktopSelected;
          this.mapMarkerMobileUnselected = mapMarkerMobileUnselected;
          this.mapMarkerMobileSelected = mapMarkerMobileSelected;
          this.getPartnerInformation();
        }
      );
  }

  private scrollToSelectedLocation() {
    const selectedLocation = document.querySelector(
      "[ng-reflect-selected=true]"
    );
    if (selectedLocation) {
      selectedLocation.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }

  private subscribeToPartnerInformationLoaded() {
    this.partnerService.isPartnerInformationLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLoaded) => {
        this.isPartnerInformationLoaded = !isLoaded;
      });
  }

  private subscribeToTimeslotInformationLoaded(): void {
    this.partnerService.isTimeslotInformationLoaded$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isLoaded) => {
        this.isTimeslotInformationLoaded = isLoaded;
      });
  }

  private updateMapMarkers(location: IPartnerLocation) {
    const mapsMarker = this.googleMapsMarker.getValue();
    if (mapsMarker !== null) {
      mapsMarker.forEach((marker) => {
        marker.selected =
          marker.position.lat === location.location.latitude &&
          marker.position.lng === location.location.longitude;
        marker.content = this.getMapMarker(marker.selected);
      });
      this.googleMapsMarker.next(mapsMarker);
    }
  }

  private updateSelectedPartnerLocation(location: IPartnerLocation) {
    this.partnerLocations!.forEach((loc) => {
      loc.selected = loc.branch_store_id === location.branch_store_id;
    });
  }
}
