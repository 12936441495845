import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { DestroyRef, inject, Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import {
  IBookAppointmentRequest,
  IBookingResponse,
  IOffer,
  IPartnerLocation,
  ISuccessResponse,
  ITimeslot,
  ITimeslots,
  SimplePartnerLocation,
} from "../model/partner.model";

@Injectable({
  providedIn: "root",
})
export class PartnerService {
  public availableTimeslots$ = new BehaviorSubject<ITimeslots | null>(null);
  public currentOfferId$ = new BehaviorSubject<string | null>(null);
  public currentPartner$ = new BehaviorSubject<SimplePartnerLocation | null>(
    null
  );

  public currentTimeslot$ = new BehaviorSubject<ITimeslot | null>(null);
  public isPartnerInformationLoaded$ = new BehaviorSubject<boolean>(false);
  public isTimeslotInformationLoaded$ = new BehaviorSubject<boolean>(false);
  public partnerLocations$ = new BehaviorSubject<IPartnerLocation[] | null>(
    null
  );

  public isTimeslotSelected$ = new BehaviorSubject<boolean>(false);
  public isTimeslotValid$ = new BehaviorSubject<boolean>(true);
  protected basePath = environment.BASE_PATH;
  private offerId: string = "";
  private readonly destroyRef = inject(DestroyRef);

  constructor(private http: HttpClient) {}

  public bookAppointment(
    branchstore_id: string,
    appointmend_id: string,
    request: IBookAppointmentRequest
  ): Observable<IBookingResponse> {
    let ng_tracking_param = ''
    if (localStorage.getItem("privacy_consent") === "true") {
      ng_tracking_param = `&ng_id=${request.ng_id}`
    }
    return this.http.post<IBookingResponse>(
      `${this.basePath}/haw/branchstore/${branchstore_id}/appointment/${appointmend_id}?offer_id=${request.offer_id}${ng_tracking_param}&appointment_start=${request.appointment_start}&appointment_end=${request.appointment_end}`,
      request.payload
    );
  }

  public getOffer(offerId: string): Observable<IOffer> {
    this.offerId = offerId;
    let params = new HttpParams();
    params = params.append("offer_id", offerId);
    return this.http.get<IOffer>(this.basePath + "/start", { params: params });
  }

  public getPartnerLocations(): Observable<IPartnerLocation[]> {
    const headers = new HttpHeaders({
      "accept-encoding": "br",
    });

    return this.http.get<IPartnerLocation[]>(
      this.basePath + "/haw/branchstore",
      {
        headers,
      }
    );
  }

  /**
   * For testing error handling with specific errors from backend
   * @param errorCode
   * @returns
   */
  public getBackendError(errorCode: string): Observable<unknown> {
    return this.http.get<IPartnerLocation[]>(
      `${this.basePath}/fail?http_code=${errorCode}`
    );
  }

  public getSuccesInformation(
    offerId: string,
    successId: string
  ): Observable<ISuccessResponse> {
    return this.http.get<ISuccessResponse>(
      `${this.basePath}/success?offer_id=${offerId}&success_id=${successId}`
    );
  }

  public setAvailableTimeslots(timeslots: ITimeslots): void {
    this.availableTimeslots$.next(timeslots);
  }

  public setCurrentOfferId(offerId: string): void {
    this.currentOfferId$.next(offerId);
  }

  public setCurrentPartner(currentPartner: SimplePartnerLocation): void {
    this.setIsPartnerInformationLoaded(false);
    this.currentPartner$.next(currentPartner);
    this.setIsPartnerInformationLoaded(true);
    this.setIsTimeslotInformationLoaded(false);
    this.getAvailableSlots(currentPartner.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((availableTimeslots) => {
        this.setAvailableTimeslots(availableTimeslots);
        this.setIsTimeslotInformationLoaded(true);
      });
  }

  public setCurrentTimeslot(timeslot: ITimeslot): void {
    this.currentTimeslot$.next(timeslot);
  }

  public setIsTimeslotSelected(isTimeslotSelected: boolean): void {
    this.isTimeslotSelected$.next(isTimeslotSelected);
  }

  public setIsTimeslotValid(isTimeslotValid: boolean): void {
    this.isTimeslotValid$.next(isTimeslotValid);
  }

  public setPartnerLocations(partnerLocations: Array<IPartnerLocation>): void {
    this.partnerLocations$.next(partnerLocations);
  }

  private getAvailableSlots(partner_id: string): Observable<ITimeslots> {
    const headers = new HttpHeaders({
      "accept-encoding": "br",
    });

    return this.http.get<ITimeslots>(
      this.basePath + "/haw/branchstore" + "/" + partner_id + "/appointment",
      { headers }
    );
  }

  private setIsPartnerInformationLoaded(isLoaded: boolean): void {
    this.isPartnerInformationLoaded$.next(isLoaded);
  }

  private setIsTimeslotInformationLoaded(isLoaded: boolean): void {
    this.isTimeslotInformationLoaded$.next(isLoaded);
  }
}
