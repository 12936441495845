<div class="wrapper">
    <div class="image-mobile">
        <img src="assets/hero-image.webp" alt="Ein Mann steht vor einem Gebäude mit dem Logo 'HUK Autowelt' und mehreren geparkten Autos.">
    </div>
    <div class="content">
        <div class="image-desktop">
            <img src="assets/hero-image.webp" alt="Ein Mann steht vor einem Gebäude mit dem Logo 'HUK Autowelt' und mehreren geparkten Autos.">
        </div>
        <div class="text">
            <h1 class="s:text-h1 s:text-bold">Die Aktion ist leider schon vorbei</h1>
            <p class="s:text-body-1 s:text">Sie können Ihr Auto dennoch von der HUK-AUTOWELT schätzen lassen und bei
                einer
                HUK-Ankaufstation in Ihrer
                Nähe
                verkaufen. Folgen Sie dazu folgendem Link.</p>
            <s-button class="s:text-body-1 s:text-bold" variant="filled" url="https://www.huk-autowelt.de/auto_verkaufen">
                Zur HUK-AUTOWELT
            </s-button>
        </div>
    </div>
</div>