import { Component } from '@angular/core';
import {FormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";
import {ShieldModule} from "@shield/angular";

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    ShieldModule
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
}
