import { Component } from '@angular/core';
import {ShieldModule} from "@shield/angular";

@Component({
  selector: 'app-conditions',
  standalone: true,
  imports: [
    ShieldModule
  ],
  templateUrl: './conditions.component.html',
  styleUrl: './conditions.component.scss'
})
export class ConditionsComponent {

}
